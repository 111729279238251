/**
 * Projects list
 */
 const projectList = [
    {
      title: 'Brand Logo Design',
      content: 'Sed ut perspiciatis unde iste',
      category: 'active',
      department: 'Design',
      date: '20 May, 2021',
      status: 'Active',
      bg_color: 'bg-warning',
      users: [
        {
          name: 'Janna Johnson',
          profile: require('@/assets/images/users/avatar-1.jpg')
        },
        {
          name: 'Heather Ford',
          profile: require('@/assets/images/users/avatar-2.jpg')
        },
        {
          name: 'Eliza Hardin',
          variant: 'bg-info'
        },
        {
          name: 'Megan Seaton',
          profile: require('@/assets/images/users/avatar-4.jpg')
        }
      ]
    },
    {
      title: 'Chat App',
      content: 'Quis autem vel eum iure',
      category: 'completed',
      department: 'Development',
      date: '23 Apr, 2021',
      status: 'Completed',
      bg_color: 'bg-success',
      users: [
        {
          name: 'Megan Seaton',
          profile: require('@/assets/images/users/avatar-4.jpg')
        },
        {
          name: 'Ronald Hatfield',
          profile: require('@/assets/images/users/avatar-5.jpg')
        },
        {
          name: 'Heather Ford',
          profile: require('@/assets/images/users/avatar-2.jpg')
        }
      ]
    },
    {
      title: 'Authentication',
      content: 'At vero eos et accusamus et',
      category: 'active',
      department: 'Development',
      date: '10 Jun, 2021',
      status: 'Active',
      bg_color: 'bg-info',
      users: [
        {
          name: 'Ronald Hatfield',
          profile: require('@/assets/images/users/avatar-10.jpg')
        }
      ]
    },
    {
      title: 'Dashboard UI',
      content: 'Duis arcu suscipit eget',
      category: 'completed',
      department: 'Design',
      date: '20 Apr, 2021',
      status: 'Completed',
      bg_color: 'bg-success',
      users: [
        {
          name: 'Eliza Hardin',
          profile: require('@/assets/images/users/avatar-4.jpg')
        },
        {
          name: 'Ronald Hatfield',
          profile: require('@/assets/images/users/avatar-5.jpg')
        }
      ]
    }
  ];
  
  export { projectList };